// BREAKINGBAR
(() => {
  const BREAKING_BAR_ELEMENT_SELECTOR = 'section.tc_breakingbar';
  const BREAKING_BAR_ARTICLE_ELEMENT_SELECTOR = 'li.tc_breakingbar__item';
  const BREAKING_BAR_BUTTON_ELEMENT_SELECTOR = '.tc_breakingbar__button__close';
  const HAS_SEEN_KEY = 'has_seen_breaking';
  const NEW_BREAKING_BAR_TIMER_INTERVAL = 25000;

  let urlDataElement = '';

  const getSeenArticleIds = () => {
    const seenIdsString = window.tv2.utils.getCookie(HAS_SEEN_KEY);

    if (!seenIdsString) {
      return [];
    }

    return JSON.parse(seenIdsString);
  };

  const getAllArticleIdsFromDOM = () => {
    const articles = document.querySelectorAll(
      BREAKING_BAR_ARTICLE_ELEMENT_SELECTOR,
    );

    let ids = [];

    let n;
    for (n = 0; n < articles.length; n += 1) {
      if (articles[n].id) {
        ids.push(articles[n].id);
      }
    }

    return ids;
  };

  const updateSeenBreakingIds = () => {
    const newIds = getAllArticleIdsFromDOM();

    if (newIds && newIds.length > 0) {
      const previousIds = getSeenArticleIds();

      const allIds = newIds.concat(previousIds);

      const idString = JSON.stringify(allIds);

      window.tv2.utils.setCookie(HAS_SEEN_KEY, idString);
    }
  };

  const hideBreakingBar = (breakingBar) => {
    if (breakingBar) {
      breakingBar.innerHTML = '';
      breakingBar.setAttribute('hidden', 'hidden');
    }
  };

  const showBreakingBar = (breakingBar) => {
    if (breakingBar) {
      breakingBar.removeAttribute('hidden');
    }
  };

  const closeBreakingBar = (breakingBar) => {
    updateSeenBreakingIds();

    hideBreakingBar(breakingBar);
  };

  const breakingLinkClickHandler = () => {
    updateSeenBreakingIds();
  };

  const setupBreakingBarLinks = (links) => {
    let n;
    for (n = 0; n < links.length; n += 1) {
      links[n].addEventListener('click', breakingLinkClickHandler);
    }
  };

  const attachListenersToBreakingBar = (breakingBar) => {
    const breakingBarCloseButton = breakingBar.querySelector(
      BREAKING_BAR_BUTTON_ELEMENT_SELECTOR,
    );

    if (breakingBarCloseButton) {
      breakingBarCloseButton.addEventListener(
        'click',
        closeBreakingBar.bind(null, breakingBar),
      );
    }

    const breakingBarArticleLinks = breakingBar.querySelectorAll(
      BREAKING_BAR_ARTICLE_ELEMENT_SELECTOR + ' a',
    );

    if (breakingBarArticleLinks && breakingBarArticleLinks.length > 0) {
      setupBreakingBarLinks(breakingBarArticleLinks);
    }
  };

  const setupBreakingBarListeners = () => {
    const breakingBar = document.querySelector(BREAKING_BAR_ELEMENT_SELECTOR);

    if (breakingBar) {
      attachListenersToBreakingBar(breakingBar);
    }
  };

  const removeSeenArticles = (html, filterIds) => {
    let n;
    for (n = 0; n < filterIds.length; n += 1) {
      const seenArticle = html.getElementById(filterIds[n]);

      if (seenArticle) {
        seenArticle.parentNode.removeChild(seenArticle);
      }
    }
  };

  const getFilteredBreakingBarHtml = (barHtml) => {
    const seenIds = getSeenArticleIds();

    if (seenIds) {
      removeSeenArticles(barHtml, seenIds);
    }

    return barHtml;
  };

  const allBreakingArticlesAlreadyInBar = (newFilteredBreakingBar) => {
    const currentBreakingArticleIds = getAllArticleIdsFromDOM();

    if (currentBreakingArticleIds) {
      removeSeenArticles(newFilteredBreakingBar, currentBreakingArticleIds);
    }

    const newBreakingArticle = newFilteredBreakingBar.querySelector(
      BREAKING_BAR_ARTICLE_ELEMENT_SELECTOR,
    );
    return !newBreakingArticle;
  };

  const parseHtmlString = (htmlString) => {
    const domParser = new DOMParser();
    return domParser.parseFromString(htmlString, 'text/html');
  };

  const getNewBreakingBarWithFilteredArticles = (filteredHtmlDocument) => {
    const newBreakingArticlesNotSeen = filteredHtmlDocument.querySelectorAll(
      BREAKING_BAR_ARTICLE_ELEMENT_SELECTOR,
    );

    if (newBreakingArticlesNotSeen && newBreakingArticlesNotSeen.length > 0) {
      return filteredHtmlDocument.querySelector('section');
    }

    return null;
  };

  const insertNewBarHtmlOnPage = (oldBar, newBar) => {
    if (oldBar && newBar) {
      oldBar.outerHTML = newBar.outerHTML;
      showBreakingBar(oldBar);
    } else if (newBar) {
      const newBarInDOM = document.createElement('section');

      newBarInDOM.outerHTML = newBar.outerHTML;
      showBreakingBar(newBarInDOM);

      document.body.appendChild(newBarInDOM);
    }
  };

  const checkAndRenderNewBreakingBar = (htmlResponse, isInitialPageLoad) => {
    const oldBreakingBarElement = document.querySelector(
      BREAKING_BAR_ELEMENT_SELECTOR,
    );

    const newBreakingBarHtmlDocument = parseHtmlString(htmlResponse);

    const newFilteredBreakingBarDocument = getFilteredBreakingBarHtml(
      newBreakingBarHtmlDocument,
    );

    const newBreakingBarElement = getNewBreakingBarWithFilteredArticles(
      newFilteredBreakingBarDocument,
    );

    // if the new breaking bar is empty of breaking-articles we hide the breaking bar
    if (!newBreakingBarElement) {
      hideBreakingBar(oldBreakingBarElement);
      return;
    }

    // allBreakingArticlesAlreadyInBar is only used for some "calculation" - we clone the document to not mutate the new breaking bar
    if (
      allBreakingArticlesAlreadyInBar(
        newFilteredBreakingBarDocument.cloneNode(true),
      )
    ) {
      if (isInitialPageLoad) {
        setupBreakingBarListeners();
        showBreakingBar(oldBreakingBarElement);
      }

      return;
    }

    insertNewBarHtmlOnPage(oldBreakingBarElement, newBreakingBarElement);

    setupBreakingBarListeners();
  };

  const getNewBreakingBarFromService = () => {
    urlDataElement =
      document.querySelector('[data-tv2-breaking-bar-url]') || urlDataElement;
    if (!urlDataElement) {
      console.warn('TV2BreakingBar: Could not find "tv2-breaking-bar-url"');
      return;
    }

    const newBreakingBarUrl = urlDataElement.dataset.tv2BreakingBarUrl;
    if (!newBreakingBarUrl) {
      return;
    }

    const xhr = new XMLHttpRequest();

    xhr.onload = function handleBreakingBarResponse() {
      if (this.status === 200 && this.response) {
        checkAndRenderNewBreakingBar(this.response, false);
      }
    };

    xhr.open('GET', newBreakingBarUrl);
    xhr.responseType = 'text';
    xhr.send();
  };

  const filterBreakingBarOnPageLoad = () => {
    const breakingBar = document.querySelector(BREAKING_BAR_ELEMENT_SELECTOR);

    if (breakingBar) {
      checkAndRenderNewBreakingBar(breakingBar.outerHTML, true);
    }
  };

  filterBreakingBarOnPageLoad();

  setInterval(getNewBreakingBarFromService, NEW_BREAKING_BAR_TIMER_INTERVAL);
})();
